<template>
  <div class="index">
    <div class="content">
      <div class="header">
        <treee-logo-with-mark class="logo"/>
        <template v-if="!authorized">
          <router-link
            class="button"
            tag="button"
            to="sign_in">Sign in</router-link>
        </template>
        <template v-else>
          <router-link
            class="button"
            tag="button"
            :to="{ name: 'my_treees', params: { userId }}">My items</router-link>
        </template>
      </div>
      <img class="anime" src="/img/index/treee.gif" alt="">
      <h1 class="h1">"Treee" is tree structured text editor.</h1>
      <p class="lead">Do you know the amazing app named "tree2" ? I like it so much but it's no longer update. So I created "Treee". "Treee" is different from "tree 2" but very similar. </p>
      <p class="lead">let's use "Treee" to structure your thoughts. Program design, article outline creation, cooking recipes.</p>
      <p class="lead">"Treee" performs almost all operations on the keyboard, so learning shortcut key bindings makes editing a document very quick and easy.</p>
      <p class="lead">"Treee" works on a web browser. So it can be used on both Windows and Mac. If you have a keyboard, you can use it on an iPad.</p>
      <p class="lead">Documents are instantly stored in the cloud. You can resume your work from any device at any time.</p>
      <p class="lead"><router-link
        tag="a"
        to="release">Release note</router-link></p>
      <footer class="footer">
        <p class="description">contact me on <a href="https://twitter.com/treee_app">twitter</a> if you are interested.</p>
      </footer>
    </div>
</div>
</template>

<script>
import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import TreeeLogoWithMark from '../images/TreeeLogoWithMark.svg'

const authStore = createNamespacedHelpers('authStore')

export default Vue.extend({
  components: {
    TreeeLogoWithMark
  },
  computed: {
    ...authStore.mapState([
      'authorized',
      'userId'
    ])
  },
  mounted () {
    this.$nextTick(() => {
      if (!this.authorized) return
      this.$router.push({
        name: 'my_treees',
        params: {
          userId: this.userId
        }
      })
    })
  }
})
</script>

<style lang="scss" scoped>
  @import "../styles/constants";

  .index {
    min-width: 100vw;
    min-height: 100vh;
  }

  .content {
    width: 600px;
    margin: 0 auto;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    margin: 0 0 20px;
  }

  .button {
    @extend %typo-button;

    line-height: 1;
    border: none;
    box-sizing: border-box;
    height: 34px;
    min-width: 80px;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    background-color: $theme-a;
    color: $white;

    &:focus {
      outline: none;
    }

    &:hover {
      filter: brightness(95%);
    }

    &:active {
      filter: brightness(90%);
    }
  }

  .anime {
    width: 402px;
  }

  .h1 {
    @extend %typo-h6;

    color: $primary-d;
    margin: 0 0 5px;
  }

  .logo {
    height: 40px;
  }

  .lead {
    @extend %typo-body1;

    color: $secondary-d;
    margin: 0 0 20px;
  }

  .description {
    @extend %typo-body2;

    color: $secondary-d;
    margin: 0 0 40px;
  }

  .footer {
    text-align: center;
  }
</style>
